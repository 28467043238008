import * as TWEEN from '@tweenjs/tween.js';
import { EasingFunctionEnum } from '../enum/EasingFunctionEnum';

export const getEasingFunction = (functionName: string): any => {
  if (functionName === EasingFunctionEnum.QuadraticInOut) {
    return TWEEN.Easing.Quadratic.InOut;
  } if (functionName === EasingFunctionEnum.QuadraticIn) {
    return TWEEN.Easing.Quadratic.In;
  } if (functionName === EasingFunctionEnum.QuadraticOut) {
    return TWEEN.Easing.Quadratic.Out;
  } if (functionName === EasingFunctionEnum.CubicInOut) {
    return TWEEN.Easing.Cubic.InOut;
  } if (functionName === EasingFunctionEnum.CubicIn) {
    return TWEEN.Easing.Cubic.In;
  } if (functionName === EasingFunctionEnum.SinusoidalInOut) {
    return TWEEN.Easing.Sinusoidal.InOut;
  } if (functionName === EasingFunctionEnum.SinusoidalIn) {
    return TWEEN.Easing.Sinusoidal.In;
  } if (functionName === EasingFunctionEnum.SinusoidalOut) {
    return TWEEN.Easing.Sinusoidal.Out;
  } if (functionName === EasingFunctionEnum.CubicOut) {
    return TWEEN.Easing.Cubic.Out;
  } if (functionName === EasingFunctionEnum.ExponentialInOut) {
    return TWEEN.Easing.Exponential.InOut;
  } if (functionName === EasingFunctionEnum.ExponentialIn) {
    return TWEEN.Easing.Exponential.In;
  } if (functionName === EasingFunctionEnum.ExponentialOut) {
    return TWEEN.Easing.Exponential.Out;
  } if (functionName === EasingFunctionEnum.CircularInOut) {
    return TWEEN.Easing.Circular.InOut;
  } if (functionName === EasingFunctionEnum.CircularIn) {
    return TWEEN.Easing.Circular.In;
  } if (functionName === EasingFunctionEnum.CircularOut) {
    return TWEEN.Easing.Circular.Out;
  }
  return TWEEN.Easing.Linear.None;
};
